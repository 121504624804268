.KonvaWrapper {
    position: relative;
    // background-color: #000;

    .DOWNLOAD {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 111111;
    }

    &-Stage {

        // &_main {
        //     border: 1px solid;
        //     box-sizing: border-box;
        // }
    }
}