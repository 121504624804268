.Menu {
    width: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width .2s linear;
    overflow: hidden;
    border-top: 1px solid;
    background: #fff;

    &_opened {
        width: 300px;
    }


    .LayersSettings {
    }
}