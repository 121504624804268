.App {

  &-Content {
    height: 100%;
    position: relative;
    // display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    border: 1px solid;
    box-sizing: border-box;
    overflow: hidden;
    background: #b7b7b7;
  }
}

.App-Content .react-transform-wrapper {
  height: 100%;
  width: 100%;
}