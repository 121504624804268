.TextEditor {
    font-size: inherit;
    font-family: inherit;
    width: inherit;
    padding: inherit;
    line-height: inherit;
    text-align: inherit;
    padding: 0;
    border: 0;
    resize: none;
    letter-spacing: -0.044px;
    outline: 2px solid #000;
    border-radius: 4px;
    cursor: text;
}