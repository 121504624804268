.FontsSelector {
    
    &-AutoSizer {
        height: auto !important;
    }

    &-Item {

        .MuiTypography-root {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}