.SelectUrlDialog {

    &-Content {
        padding: 16px !important;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-x: hidden;
    }

    &-Icons {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        width: calc(100% + 16px);
    }

    &-Icon {
        width: calc(100% / 4 - 16px);

        img {
            max-width: 100%;
            max-height: 64px;
        }
    }
}