.CropDialog {

    &-Content {
        display: flex;
        justify-content: center;

        .ReactCrop__child-wrapper > img {
            max-height: 500px;
        }
        
        .ReactCrop__child-wrapper > .FullSizeImage {
            max-width: none;
            max-height: none;
        }   
    }
}